<template>
  <div>Encode</div>
</template>

<script>
export default {
  name: 'Encode'
}
</script>

<style scoped>

</style>
